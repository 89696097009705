import React from 'react';
import { Flex, Skeleton } from 'moralis-ui';
import styles from './HomeTokenAd.module.scss';

export const TokenAdBannerSkeleton = () => {
  return (
    <div className={styles.skeletonMainContainer}>
      <div className={styles.banner}>
        <Flex spacing={12} direction="row">
          <div className={styles.skeletonCircle}>
            <Skeleton type="circle" />
          </div>

          <Flex direction="column" justify="center" height="100%" spacing={2}>
            <Flex direction="row" spacing={8} align="baseline" justify="flex-start">
              <div className={styles.skeletonTokenName}>
                <Skeleton type="text" />
              </div>
              <div className={styles.skeletonTokenSymbol}>
                <Skeleton type="text" />
              </div>
            </Flex>
            <div className={styles.skeletonPitch}>
              <Skeleton type="text" />
            </div>
          </Flex>
        </Flex>
        <div className={styles.buttonsContainer}>
          <div className={styles.skeletonButton}>
            <Skeleton type="rectangle" />
          </div>
        </div>
      </div>
    </div>
  );
};
