import React, { MouseEvent } from 'react';
import { Avatar, ButtonSecondary, Flex } from 'moralis-ui';
import { TokenAdsView } from '@/services/models/GetTokenAdsViewDto';
import { faDiscord, faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './HomeTokenAd.module.scss';

export const TokenAdBanner = ({ tokenAdsView }: { tokenAdsView: TokenAdsView }) => {
  const hasCta = Boolean(tokenAdsView?.cta?.text);

  const hasTokenUrl = hasCta ? tokenAdsView?.cta?.link : tokenAdsView.tokenURL;

  const hrefLinkCta = hasCta ? tokenAdsView?.cta?.link : tokenAdsView.tokenURL;

  const twitterLink = tokenAdsView?.links?.find((link) => link.name === 'X');
  const discordLink = tokenAdsView?.links?.find((link) => link.name === 'discord');
  const telegramLink = tokenAdsView?.links?.find((link) => link.name === 'telegram');
  const websiteLink = tokenAdsView?.links?.find((link) => link.name === 'token-project-link');

  const handleClickLink = (link: string) => (event: MouseEvent) => {
    event.preventDefault();
    window.open(link, '_blank');
  };

  return (
    <div className={styles.banner}>
      <Flex spacing={12} direction="row">
        {tokenAdsView?.icon && URL.canParse(tokenAdsView.icon) && (
          <Avatar alt="token avatar" size="md" src={tokenAdsView.icon} />
        )}

        <Flex direction="column" justify="center" height="100%" spacing={2}>
          <Flex direction="row" spacing={8} align="baseline" justify="flex-start">
            <p className={styles.tokenName}>{tokenAdsView?.tokenName || ''}</p>
            <p className={styles.tokenSymbol}>{tokenAdsView?.tokenSymbol || ''}</p>
          </Flex>
          <p className={styles.pitch}>{tokenAdsView?.pitch}</p>
        </Flex>
      </Flex>
      <div className={styles.buttonsContainer}>
        <Flex spacing={8} direction="row" justify="center" width={{ xs: '100%', md: 'auto' }}>
          {discordLink ? (
            <ButtonSecondary size="xs" onClick={handleClickLink(discordLink.url)}>
              <FontAwesomeIcon className={styles.socialMediaIcon} icon={faDiscord} />
            </ButtonSecondary>
          ) : null}
          {telegramLink ? (
            <ButtonSecondary size="xs" onClick={handleClickLink(telegramLink.url)}>
              <FontAwesomeIcon className={styles.socialMediaIcon} icon={faTelegram} />
            </ButtonSecondary>
          ) : null}
          {websiteLink ? (
            <ButtonSecondary size="xs" onClick={handleClickLink(websiteLink.url)}>
              <FontAwesomeIcon className={styles.socialMediaIcon} icon={faGlobe} />
            </ButtonSecondary>
          ) : null}
          {twitterLink ? (
            <ButtonSecondary size="xs" onClick={handleClickLink(twitterLink.url)}>
              <FontAwesomeIcon className={styles.socialMediaIcon} icon={faTwitter} />
            </ButtonSecondary>
          ) : null}
        </Flex>
        {hasTokenUrl && (
          <ButtonSecondary size="xs" className={styles.ctaButton} onClick={handleClickLink(hrefLinkCta)}>
            {hasCta ? tokenAdsView.cta.text : 'Check Chart'}
          </ButtonSecondary>
        )}
      </div>
    </div>
  );
};
