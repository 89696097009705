import { useCallback } from 'react';
import useAnalyticsStore from '@/stores/analyticsStore';
import { TNavLinkClickParams } from '@/types/TNavLinkClickParams';

export enum AdSource {
  HOME_TRENDING_TOKENS_TABLE = 'Home Trending Tokens Table',
  HOME_BANNER = 'Home Banner',
  TOKEN_PAGE = 'Token Page',
}

export const useAds = (source: AdSource) => {
  const tracker = useAnalyticsStore((state) => state.tracker);

  const handleOnAdClick = useCallback(
    async ({ href, text }: TNavLinkClickParams) => {
      tracker?.trackLinkClick(`${source} Ad: ${text} (${href})`);
    },
    [source],
  );

  return { handleOnAdClick };
};
